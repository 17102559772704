body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root {
  display: flex;
  flex-direction: column;
}

.ant-card-body {
  padding: 12px !important;
}

.logo {
  height: 42px; /* Adjust height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;;
  border-radius: 6px;
  margin: 16px;
  overflow: hidden; /* Ensure content stays within the box */
}

.logo-img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}
